import React, {lazy, Suspense, useState} from 'react';
import {useParams, useSearchParams} from "react-router-dom/index";
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import './App.css';
import {config as APICONFIG} from "./config";
import ApiService from './Service/ApiService';
import LinearProgress from "@material-ui/core/LinearProgress";
import {CustomerMenus} from "./Blocks/Menus";

const CustomersMap = lazy(() => import("./Blocks/Map"));
const Customers = lazy(() => import("./Pages/Customers"));
const Customer = lazy(() => import("./Pages/Customer"));
const CustomerEmbed = lazy(() => import("./Pages/CustomerEmbed"));
const CustomerMenuEmbed = lazy(() => import("./Pages/CustomerMenuEmbed"));
const CustomerMenuEmbedFeatured = lazy(() => import("./Pages/CustomerMenuEmbedFeatured"));
const CustomerPlugin = lazy(() => import("./Pages/CustomerPlugin"));


const APISERVICE = new ApiService(APICONFIG);


const App = () => {

    let slug = document.currentScript.getAttribute('slug');


    if (slug) return <React.StrictMode>
        <BrowserRouter>
            <Suspense fallback={<LinearProgress/>}>
                <Routes>
                    <Route path="*" element={<CustomerPlugin APISERVICE={APISERVICE} ownerSlug={slug}/>}/>
                </Routes>
            </Suspense>
        </BrowserRouter>
    </React.StrictMode>;

    return <React.StrictMode>
        <BrowserRouter>
            <Suspense fallback={<LinearProgress/>}>
                <Routes>
                    <Route path="/" element={<Customer APISERVICE={APISERVICE}/>} pathname={"customer"}/>
                    {/*<Route path="/clientes" element={<Customers APISERVICE={APISERVICE}/>} pathname={"customers"}/>*/}
                    <Route path="/clientes/:ownerSlug" element={<Customer APISERVICE={APISERVICE}/>}
                           pathname={"customer"}/>
                    <Route
                        path="/clientes/embed/:ownerSlug"
                        element={<CustomerEmbed APISERVICE={APISERVICE}/>}
                        pathname={"customer"}
                    />
                    <Route
                        path="/clientes/:ownerSlug/menus/:menuId"
                        element={<CustomerMenuEmbed APISERVICE={APISERVICE}/>}
                        pathname={"customer-menus"}
                    />
                    <Route
                        path="/clientes/:ownerSlug/featured-menu/:menuId"
                        element={<CustomerMenuEmbedFeatured APISERVICE={APISERVICE}/>}
                        pathname={"customer-menus"}
                    />
                </Routes>
            </Suspense>
        </BrowserRouter>
        <footer className="text-center text-lg-start bg-light text-muted">

            <section
                className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"
            >

                <div className="me-5 d-none d-lg-block">
                    <span></span>
                </div>


            </section>

            <section className="">
                <div className="container text-center text-md-start mt-5">
                    <div className="row mt-3">
                        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">
                                MOCHI
                            </h6>
                            <p>
                                Alimentación bajo control.
                            </p>
                            <p>
                                <a href="https://mochi.gdn" target={"_blank"} className="text-reset">mochi.gdn</a>
                            </p>
                        </div>

                        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

                            <h6 className="text-uppercase fw-bold mb-4">
                                MÓDULOS
                            </h6>
                            <p>
                                <a href="https://mochi.gdn/#block-anchor" target={"_blank"} className="text-reset">Cartas</a>
                            </p>
                            <p>
                                <a href="https://mochi.gdn/#block-anchor" className="text-reset">Escandallos</a>
                            </p>
                            <p>
                                <a href="https://mochi.gdn/#block-anchor" className="text-reset">Valores nutricionales</a>
                            </p>
                            <p>
                                <a href="https://mochi.gdn/autocontrol-digital/" className="text-reset">Autocontrol digital</a>
                            </p>

                            <p>
                                <a href="https://mochi.gdn/etiquetas-electronicas-para-buffet/" className="text-reset">Etiquetas electrónicas para buffet</a>
                            </p>
                        </div>

                        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">
                                Contacto
                            </h6>
                            {/*<p><i className="fas fa-home me-3"></i>Tenerife, Arona</p>*/}
                            <p>
                                <i className="fas fa-envelope me-3"></i>
                                info@mochi.gdn
                            </p>
                            <p><i className="fas fa-phone me-3"></i> + 34 681202814</p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="text-center p-4" style={{backgroundColor: "rgba(0, 0, 0, 0.05)"}}>
                © 2022
                <a className="text-reset fw-bold" href="https://closure.es/">CLOSURE</a>
            </div>

        </footer>
    </React.StrictMode>
}

ReactDOM.render(
    <App />,
    document.getElementById('root')
);


